import React, { useState } from 'react';

import SEO from "../components/seo"
import Slider from 'react-slick';
import { graphql } from 'gatsby';
import { Layout } from '../layout/Layout';
import { countriesTranslations, categories } from '../translations/clients';

const sliderSettings = {
  dots: false,
  arrows: false,
  infinite: true,
  variableWidth: true,
  focusOnSelect: true,
  slidesToShow: 4,
  responsive: [
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
      },
    },
    {
      breakpoint: 486,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,
      },
    },
  ],
};

const Clientes = ({ data, pageContext }) => {
  const { title, highlight, text } = data.directus.clients_page_translations_by_id;
  const { latin_america, europe, africa, asia } = data.directus.clients_page;
  const [contentIndex, setContentIndex] = useState(0);
  const countriesTranslation = countriesTranslations[pageContext.languageCode]

  return (
    <Layout pageId="clientes" currentLanguage={pageContext.languageCode}>
      <section className="banner">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto">
              <h1>{title}</h1>
              <p className="big-text">{highlight}</p>
              <p>{text}</p>
            </div>
          </div>
        </div>
      </section>

      <section className="countries">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto countries__selector">
              <Slider {...sliderSettings} className="select-slider">
                {categories[pageContext.languageCode].map((category, index) => (
                  <button
                    key={category}
                    className={`btn ${contentIndex === index ? 'active' : undefined}`}
                    onClick={() => setContentIndex(index)}
                  >
                    {category}
                  </button>
                ))}
              </Slider>
            </div>
          </div>

          <div className="row">
            <ul className="col-md-10 mx-auto countries__list" style={{ display: contentIndex !== 0 && 'none' }}>
              {latin_america.map(element => (
                <li key={element.country} className="country">
                  <h2>{countriesTranslation[element.country_code] || element.country}</h2>
                  <ul>
                    {element.clients
                      .split('\n')
                      .filter(p => p !== '')
                      .map(client => (
                        <li key={client}>{client}</li>
                      ))}
                  </ul>
                </li>
              ))}
            </ul>

            <ul className="col-md-10 mx-auto countries__list" style={{ display: contentIndex !== 1 && 'none' }}>
              {europe.map(element => (
                <li key={element.country} className="country">
                  <h2>{countriesTranslation[element.country_code] || element.country}</h2>
                  <ul>
                    {element.clients
                      .split('\n')
                      .filter(p => p !== '')
                      .map(client => (
                        <li key={client}>{client}</li>
                      ))}
                  </ul>
                </li>
              ))}
            </ul>

            <ul className="col-md-10 mx-auto countries__list" style={{ display: contentIndex !== 2 && 'none' }}>
              {africa.map(element => (
                <li key={element.country} className="country">
                  <h2>{countriesTranslation[element.country_code] || element.country}</h2>
                  <ul>
                    {element.clients
                      .split('\n')
                      .filter(p => p !== '')
                      .map(client => (
                        <li key={client}>{client}</li>
                      ))}
                  </ul>
                </li>
              ))}
            </ul>

            <ul className="col-md-10 mx-auto countries__list" style={{ display: contentIndex !== 3 && 'none' }}>
              {asia.map(element => (
                <li key={element.country} className="country">
                  <h2>{countriesTranslation[element.country_code] || element.country}</h2>
                  <ul>
                    {element.clients
                      .split('\n')
                      .filter(p => p !== '')
                      .map(client => (
                        <li key={client}>{client}</li>
                      ))}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query ClientsPageQuery($translationId: ID!) {
    directus {
      clients_page {
        latin_america
        europe
        africa
        asia
      }
      clients_page_translations_by_id(id: $translationId) {
        title
        highlight
        text
      }
    }
  }
`;

export default Clientes;

export const Head = () => <SEO title="Clients - MicroRate" />;
