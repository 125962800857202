import en from 'react-phone-number-input/locale/en';
import es from 'react-phone-number-input/locale/es';
import fr from 'react-phone-number-input/locale/fr';
import pt from 'react-phone-number-input/locale/pt';

en.RU = 'Russia';
es.RU = 'Russia';
fr.RU = 'Russie';
pt.RU = 'Rússia';

es.CD = 'República Democrática del Congo';
en.CD = 'Democratic Republic of Congo';
fr.CD = 'République démocratique du Congo';
pt.CD = 'República Democrática do Congo';

en.CI = 'Ivory Coast';

fr.CG = 'Congo';

export const countriesTranslations = {
  'en-US': en,
  'es-ES': es,
  'fr-FR': fr,
  'pt-BR': pt,
};

export const categories = {
  'en-US': ['Latin America and Caribbean', 'Europe', 'Africa', 'Asia'],
  'es-ES': ['Latinoamérica y el Caribe', 'Europa', 'África', 'Asia'],
  'fr-FR': ['Amérique Latine et Caraïbes', 'Europe', 'Afrique', 'Asie'],
  'pt-BR': ['América Latina e Caribe', 'Europa', 'África', 'Ásia'],
};
